import * as React from 'react';
import Helmet from 'react-helmet';

import { Intro } from 'components/intro/Intro';
import { Highlight } from 'components/intro/Highlight';
import { BlockText } from 'components/block-text/BlockText';

export default () => (
  <>
    <Helmet title="Home" />

    <Intro>
      Hi I'm Chris,
      <br/>
      <br/>
      A Technology Director with over 15 years of digital agency experience. Drop me a line at <Highlight>chris@burke.io</Highlight>
    </Intro>

    {/* <BlockText
      heading="Who we are"
      description={(
        <>
          Ueno is a full-service agency, busy designing and building
          beautiful digital products, brands, and experiences. For more
          informations go to <a href="https://ueno.co" target="_blank" rel="noopener noreferrer">ueno.co</a>.
        </>
      )}
    /> */}
  </>
);
